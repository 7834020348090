<template>
  <p class="title-p" style="width: 100%;">公众号开发信息</p>
  <el-row :gutter="20" style="height: calc(100% - 31px)">
    <el-col :span="24">
      <div class="ctent" style="position: relative">
        <div class="title-tool department-tool">

          <div style="display: flex;">
            <div class="tool-item" style="width: 400px;">
              <div class="tool-label">
                搜索：
              </div>
              <el-input v-model="search" placeholder="输入班级、老师搜索" style="width: 100%;"/>
            </div>

            <div class="tool-item" style="color: #ffffff;">
              <el-button color="#2968D4" type="primary" @click="selectBtn">查 询</el-button>
              <el-button @click="resetBtn">重置</el-button>
            </div>
          </div>

          <div class="add-class" style="margin-top: 0;margin-right: 15px;" @click="addClass">
            新增公众号
          </div>

        </div>
        <div class="department-table">
          <el-table :data="clasTableData" style="width: 100%" :row-style="{height:'65px'}">
            <el-table-column prop="app_name" label="公众号名称" align="center"/>
            <el-table-column prop="appid" label="开发者ID(AppID)" align="center" show-overflow-tooltip/>
            <el-table-column prop="note" label="公众号描述" align="center">
              <template #default="scope">
                <el-tag v-if="scope.row.type === '0'">服务号</el-tag>
                <el-tag v-else>订阅号</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="note" label="公众号描述" align="center"/>
            <el-table-column prop="add_time" label="添加时间" align="center"/>
            <el-table-column label="操作">
              <template #default="scope">
                <div style="display: flex; align-items: center">
                  <el-popconfirm title="确定要删除吗?" @confirm="delClass(scope.row)">
                    <template #reference>
                      <button class="el-button el-button--text el-button--small" type="button">
                        <span style="color: #f56c6c;">删除</span>
                      </button>
                    </template>
                  </el-popconfirm>
                </div>
              </template>
            </el-table-column>
            <!-- <el-table-column prop="job_number" label="工号"  align="center"/> -->
          </el-table>
        </div>
        <!-- 分页 -->
        <div class="pagination-box">
          <el-pagination
              v-model:currentPage="nowPage"
              v-model:page-size="pageLimit"
              layout="total,prev, pager, next, jumper"
              :total="tableTotal"
              @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </el-col>
  </el-row>
  <!-- 新增班级 -->
  <el-dialog v-model="dialogFormVisibleClass" :title="classTitle"
             :close-on-click-modal="false"
             @closed="closedCloseClass">
    <el-form :model="classForm" :label-width="160">
      <el-form-item label="公众号类型" >
        <el-radio-group v-model="classForm.type" >
          <el-radio label="0" >服务号</el-radio>
          <el-radio label="1" >订阅号</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="公众号名称" >
        <el-input v-model="classForm.app_name" autocomplete="off" />
      </el-form-item>
      <el-form-item label="开发者ID(AppID)"  >
        <el-input v-model="classForm.appid" autocomplete="off" />
      </el-form-item>
      <el-form-item label="开发者密码(AppSecret)" >
        <el-input v-model="classForm.appsecret" autocomplete="off" show-password type="password" />
      </el-form-item>
      <el-form-item label="公众号描述" >
        <el-input v-model="classForm.note" autocomplete="off" type="textarea" :rows="2"/>
      </el-form-item>
    </el-form>
    <template #footer>
	        <span class="dialog-footer">
	          <el-button @click="dialogFormVisibleClass = false">取消</el-button>
	          <el-button type="primary" @click="addClassForm">
	            确认
	          </el-button>
	        </span>
    </template>
  </el-dialog>
</template>

<script>
import { ElMessage } from 'element-plus';
import md5 from 'js-md5';
export default {
  name: "officialAccountSetting",
  data() {
    return {
      userData:JSON.parse(sessionStorage.getItem('ucode')),
      classTitle:'添加用户',
      classForm:{
        app_name:'',
        type:'0',
        appid:'',
        appsecret:'',
        note:''
      },
      clasTableData:[],
      search:'',
      gradeName:'',
      teacherName:'',
      dialogFormVisibleClass:false,
      nowPage:1,
      pageLimit:9,
      tableTotal:0,
      treeList:[],
      nowTreeCheck:'',
      parent_id:0,
      isAddGrade:true,
      nowEditGrade :'',
      nowEditClass:'',
      isAddClass:true
    }
  },
  methods:{
    addClassForm(){
      requestfaceWithHeadersfn("wechat/index.php","addWechatInfo",{
        keyword:'addWechatInfo',
        app_name:this.classForm.app_name,
        appid:this.classForm.appid,
        appsecret:this.classForm.appsecret,
        note:this.classForm.note,
        type:this.classForm.type,
      },"01").then((data)=>{
        console.log(data);
        //数据返回成功
        if(data.success){
          ElMessage.success(data.msg);
          // 班级列表
          this.getClassTableData();
          this.dialogFormVisibleClass = false;
        }else{
          ElMessage.error(data.msg)
        }
      }).catch((result=>{
        //数据返回失败
      }));
    },
    delClass(data){
      vaildInterface("wechat/user.php","delUser",{
        keyword:'delUser',
        user_id:data.user_id,
      },"01").then((data)=>{
        //数据返回成功
        if(data.success){
          ElMessage.success(data.msg);
          // 班级列表
          this.getClassTableData();
        }else{
          ElMessage.error(data.msg)
        }
      }).catch((result=>{
        //数据返回失败
      }));
    },
    addUpdateFomr(user_id,username,password,account,note){
      vaildInterface("wechat/user.php","addUser",{
        keyword:'addUser',
        user_id:user_id,
        username:username,
        password:password,
        account:account,
        note:note,
      },"01").then((data)=>{
        console.log(data);
        //数据返回成功
        if(data.success){
          ElMessage.success(data.msg);
          // 班级列表
          this.getClassTableData();
          this.dialogFormVisibleClass = false;
        }else{
          ElMessage.error(data.msg)
        }
      }).catch((result=>{
        //数据返回失败
      }));
    },
    closedCloseClass(){
      this.classForm = {
        app_name:'',
        type:'0',
        appid:'',
        note:'',
        appsecret:''
      };
      this.nowEditClass = '';
    },
    addClass(){
      this.classTitle = '新增公众号';
      this.isAddClass = true;
      this.dialogFormVisibleClass = true;
    },
    handleCurrentChange(val) {
      this.nowPage = val;
      // 班级列表
      this.getClassTableData();
    },
    getClassTableData(){
      requestfaceWithHeadersfn("wechat/index.php","getWechatList",{
        keyword:'getWechatList',
        limit:this.pageLimit.toString(),
        page:this.nowPage.toString(),
        search:this.search
      },"01").then((data)=>{
        console.log(data);
        //数据返回成功
        if(data.success){
          this.clasTableData = data.data.list;
          this.tableTotal = data.data.total;
        }else{
          ElMessage.error(data.msg)
        }
      }).catch((result=>{
        //数据返回失败
      }));
    },
    selectBtn(){
      // 班级列表
      this.getClassTableData();
    },
    resetBtn(){
      this.search = ''
    }
  },
  mounted() {
    // 获取列表
    this.getClassTableData();
  }

}
</script>

<style scoped>
.item-car{
  background-color: #fff;
  border-radius: 3px;
  height: 100%;
  padding: 5px 8px;
  box-sizing: border-box;
  overflow-y: auto;
}
.item-title-line{
  font-weight: bold;
  color: #333333;
  border-bottom: #E7E7E7 1px solid;
  width: 100%;
  padding-bottom: 15px;
  padding-top: 10px;
}
.ul-tree-box{
  width: 100%;
  box-sizing: border-box;
  padding-top: 10px;
}
.ul-tree-item{
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  box-sizing: border-box;
  padding: 0 10px;
  border-radius: 5px;
}
.ul-tree-item.active{
  background: #ECF2FE;
  color: #0052D9;
}
.truncate{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.tree-icon-box{
  min-width: 45px;
  box-sizing: border-box;
  padding-top: 6px;
}
.add-class{
  margin-top: 10px;
  color: #0052D9;
  text-align: left;
  box-sizing: border-box;
  padding-left: 25px;
  cursor: pointer;
}
.ctent {
  width: 100%;
  margin: 0 auto;
  height: 100%;
  padding: 30px;
  box-sizing: border-box;
  background-color: #ffffff;
  border-radius: 10px;
  padding-top: 5px;
}
.title-tool {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tool-item {
  width: 26%;
  height: 48px;
  display: flex;
  align-items: center;
  margin-right: 50px;
}

.tool-label {
  width: 80px;
  min-width: 80px;
  color: #303133;
}
.department-table {
  width: 100%;
  height: calc(100% - 80px);
  overflow: auto;
  padding-top: 10px;
  box-sizing: border-box;
  position: relative;
}

.pagination-box {
  position: absolute;
  bottom: 20px;
  left: 50%;
  bottom: 17px;
  width: 100%;
  transform: translate(-50%, 10px);
  box-sizing: border-box;
  padding: 0 25px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
</style>